<template>
  <div id="upload">
    <el-dialog :title="uploadType.title" :visible.sync="uploadShow" :close-on-click-modal="false" width="30%">
      <el-upload class="upload-demo" :action="upUrl" name="file" ref="upload" :headers="Token()"
        :limit="uploadType.limit" :multiple="uploadType.multiple" :accept="uploadType.accept" :on-success="onSuccess"
        :on-remove="onRemove" :on-exceed="onExceed" :file-list="fileList" :auto-upload="false" :list-type="[1,2,8].includes(fileType)?'picture':'text'" 
        auto-upload :before-upload="beforeAvatarUpload">
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        <div slot="tip" class="el-upload__tip">
          <span class="tip-text">{{ uploadType.tip }}</span>
        </div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    fileType: {
      type: Number,
      default: 1,
    },
    maxlimit: {
      type: Number,
      default: 10,
    },
    action: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uploadShow: false, //弹框开关
      fileTypeOpt: {
        1: {
          title: "单图上传",
          multiple: false,
          limit: 1,
          tip: "只能上传单张jpg/png文件",
          accept: ".png,.jpg,.gif,.jpeg",
          maxsize: "500",
          maxsizeTips: "最大只能上传500kb图片",
        },
        2: {
          title: "多图上传",
          multiple: true,
          limit: 10,
          tip: `只能上传最多10张jpg/png图片`,
          accept: ".png,.jpg,.gif,.jpeg",
          maxsize: "500",
          maxsizeTips: "最大只能上传500kb图片",
        },
        3: {
          title: "图文上传",
          multiple: true,
          limit: 10,
          tip: `能上传最多10个图片和文件`,
          accept: ".png,.jpg,.gif,.jpeg,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.rtf,.txt",
          maxsize: "500",
          maxsizeTips: "最大只能上传500kb图片或文件",
        },
        4: {
          title: "多文件上传",
          multiple: true,
          limit: 10,
          tip: `只能上传最多10个文件`,
          accept: ".doc,.docx,.xls,.xlsx,.ppt,.pptx,.rtf,.txt",
          maxsize: "500",
          maxsizeTips: "最大只能上传500kb文件",
        },
        5: {
          title: "单文件上传",
          multiple: false,
          limit: 1,
          tip: "只能上传单个文件",
          accept: ".doc,.docx,.xls,.xlsx,.ppt,.pptx,.rtf,.txt",
          maxsize: "500",
          maxsizeTips: "最大只能上传500kb文件",
        },
        6: {
          title: "视频上传",
          multiple: false,
          limit: 1,
          tip: "只能上传单个mp4视频文件",
          accept: ".mp4",
          maxsize: "20480",
          maxsizeTips: "最大只能上传20MB以内的视频",
        },
        7: {
          title: "表格上传",
          multiple: false,
          limit: 1,
          tip: "只能上传单个excle表格",
          accept: ".xls,.xlsx",
          maxsize: "1024",
          maxsizeTips: "最大只能上传1MB以内的表格",
        },
        8: {
          title: "单图上传",
          multiple: false,
          limit: 1,
          tip: "只能上传单张jpg/png文件",
          accept: ".png,.jpg,.jpeg",
          maxsize: "1024",
          maxsizeTips: "最大只能上传1M图片",
        },
      },
      fileList: [], //文件列表
      fileIds: "", //文件ID集合
    };
  },
  computed: {
    upUrl() {
      if (this.action) {
        return "/aps/file/public/upload"
      } else {
        return "/aps/file/upload"
      }
    },

    uploadType() {
      let obj = this.fileTypeOpt[this.fileType];
      switch (this.fileType) {
        case 2:
          obj.limit = this.maxlimit;
          obj.tip = `只能上传最多${this.maxlimit}张图片`;
          break;
        case 3:
          obj.limit = this.maxlimit;
          obj.tip = `能上传最多${this.maxlimit}个图片和文件`;
          break;
        case 4:
          obj.limit = this.maxlimit;
          obj.tip = `能上传最多${this.maxlimit}个文件`;
          break;
        default:
          break;  
      }
      return obj;
    },
  },
  methods: {
    // 【转换】获取Token
    Token() {
      let token = localStorage.getItem("playOne-store-token");
      return { Authorization: token };
    },

    // 
    getFileIds(fileList) {
      let fileIds = [];
      for (let i of fileList) {
        fileIds.push(i.response && i.response.data);
      }
      this.fileIds = fileIds.toString()
    },

    // 【监听】打开弹框
    onUpload() {
      this.uploadShow = true;
      this.fileList = [];
      this.fileIds = "";
    },

    // 【监听】上传成功
    onSuccess(res, file, fileList) {
      this.getFileIds(fileList);
    },

    // 【监听】删除预览
    onRemove(file, fileList) {
      this.getFileIds(fileList);
    },

    // 【监听】保存按钮
    onConfirm() {
      if (this.fileIds == "") return this.$message.error("请选择文件");
      this.uploadShow = false;
      this.$emit("fileIds", this.fileIds);
    },

    // 【监听】文件超出
    onExceed(files, fileList) {
      this.$message({
        message: "超出最大上传数",
        type: "warning",
      });
    },

    // 【监听】文件大小
    beforeAvatarUpload(file) {
      console.log(' file.size / 1024: ', file.size / 1024);
      const isLt2M = file.size / 1024 < this.uploadType.maxsize;
      if (!isLt2M) {
        this.$message.error(this.uploadType.maxsizeTips);
      }
      return isLt2M;
    }
  },
};
</script>

<style lang="scss" scoped>
.el-upload-list {
  overflow: auto;
}

.tip-text {
  font-size: .14rem;
  color: red;
}
</style>